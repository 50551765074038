// eslint-disable-next-line
const LOCAL_ENV = "local";
// eslint-disable-next-line
const UAT_ENV = "uat";
// eslint-disable-next-line
const PROD_ENV = "production";

// const env = LOCAL_ENV;
export const env = UAT_ENV;

const x_api_key =
  "CjP5ALkhQvXfDhk96Tq8rD384ausfKGLdYkR5HUUdW86rCj6ALe6gMWhpZHPWhbujn48Wx";
const sentry_dsn =
  "https://6cf31ace92edd4afcb082ff0eb74e020@o4505394957451264.ingest.us.sentry.io/4508040929017856";

const LOCAL = {
  API_PATH: "http://localhost:3000/",
  X_API_KEY: x_api_key,
  ENVIRONMENT: "localhost",
};

const UAT = {
  API_PATH: "https://uat-api.boahub.co/",
  X_API_KEY: x_api_key,
  ENVIRONMENT: window.location.hostname === "localhost" ? "localhost" : "uat",
  SENTRY_DSN: sentry_dsn,
  SENTRY_TRACES_SAMPLE_RATE: 1.0,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: 0.1,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: 1.0,
  SENTRY_TRACE_PROPAGATION_TARGETS: [
    "localhost",
    /^https:\/\/www\.web-uat\.boahub\.co\//,
  ],
};

const PROD = {
  API_PATH: "https://api.boahub.co/",
  X_API_KEY: x_api_key,
  ENVIRONMENT: window.location.hostname === "localhost" ? "localhost" : "prod",
  SENTRY_DSN: sentry_dsn,
  SENTRY_TRACES_SAMPLE_RATE: 1.0,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: 0.1,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: 1.0,
  SENTRY_TRACE_PROPAGATION_TARGETS: [
    "localhost",
    /^https:\/\/www\.boahub\.co\//,
  ],
};

export const envVariables = () => {
  const isProduction = PROD_ENV === env;
  const isUAT = UAT_ENV === env;

  if (isProduction) {
    return PROD;
  }

  if (isUAT) {
    return UAT;
  }
  return LOCAL;
};
