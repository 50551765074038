import axios from "axios";

export const apiGetStockAdjustmentBulk = (id) => {
  const data = {
    stockLocationId: id,
  };
  return axios
    .get(`/api/InventoryManagement/stock-adjustment-bulk-export`, {
      params: data,
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiSaveStockAdjustmentBulk = (products, stockLocationId) => {
  let data = products;

  return axios
    .post(
      `/api/InventoryManagement/stock-adjustment-bulk-import?stockLocationId=${stockLocationId}`,
      data
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};
