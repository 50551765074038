import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "./store";
import App from "./containers/App";
import registerServiceWorker from "./registerServiceWorker";
import "sanitize.css/sanitize.css";
import "./index.css";
import "./utils/axios.utils";
import "./assets/fonts/boafont.css";

import * as Sentry from "@sentry/react";
import { envVariables } from "./env";

const env = envVariables();

Sentry.init({
  dsn: env.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
  ],
  // allowUrls: ["localhost", env.API_PATH],
  environment: env.ENVIRONMENT,
  // Tracing
  tracesSampleRate: env.SENTRY_TRACES_SAMPLE_RATE, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: env.SENTRY_TRACE_PROPAGATION_TARGETS,
  // Session Replay
  // replaysSessionSampleRate: env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const _ = require("lodash");
window._ = _;

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);

registerServiceWorker();
